<template>
  <div class="row">
    <div class="col-12">
      <Widget class="h-auto">
        <template #title>Roles Eliminados</template>
        <vue-table
          v-if="vTable.headers"
          :values="items"
          :headers="vTable.headers"
          :actions="actions"
          :options="vTable.options"
          ref="vtable"
          :empty_message="canGet ? `No hay usuarios para mostrar` : `No tienes permisos para ver roles eliminados`"
          @changed="getItems"
          @onShowItem="onShowItem"
          @onActiveItem="onActiveItem"
        />
        <div v-if="canGet" class="text-center mt-3"><button class="btn btn-ligh float-right" @click="goToEvents()">Roles Activos</button></div>
      </Widget>
    </div> 
    <RoleDeletedModal
      v-if="showModal"
      @close="closeModal"
      :item="selItem"
      @saved="onSaveItem"
    />   
  </div>
</template>

<script>
import Widget from "@/components/Widget.vue";
import RoleDeletedModal from "./RoleDeletedModal.vue";
import {can, canPG} from '@/acl/methods'
export default {
  components: {
    Widget,
    RoleDeletedModal
  },
  data: () => ({
    vTable: {
      headers: [
        {
          title: "id",
          mask: "ID",
          sortable: true,
        },
        {
          title: "role_name",
          mask: "Rol",
          sortable: true,
        },
      ],
      actions: [
        {
          buttonClass: "btn-info",
          callback: "onShowItem",
          tooltip: "Mostrar Detalles",
          icon: "fa-solid fa-eye",
        },
        {
          buttonClass: "btn-warning",
          callback: "onActiveItem",
          tooltip: "Activar Rol",
          icon: "fa-solid fa-exclamation-triangle",
        },
      ],
      options: {
        /// Go to OPTIONS SECTION for explanation
      },
    },
    showModal: false,
    selItem: null,
  }),
  mounted() {
    this.initializeTable();
  },
  computed: {
    items() {
      return this.$store.getters.noRoles;
    },
    canGet(){
      return (can(this.$store.getters.user, 'roles', ['get']) && canPG(this.$store.getters.user, 'g_d_role'))
    },
    canPost(){
      return can(this.$store.getters.user, 'roles', ['post'])
    },
    canPut(){
      return (can(this.$store.getters.user, 'roles', ['put']) && canPG(this.$store.getters.user, 'p_rest_role'))
    },
    canDel(){
      return can(this.$store.getters.user, 'roles', ['delete'])
    },
    actions(){
      const actions = this.vTable.actions
      if (!this.canGet) {
        actions.splice(actions.findIndex(a => a.callback === 'onShowItem'), 1)
      }
      if (!this.canPut){
        actions.splice(actions.findIndex(a => a.callback === 'onActiveItem'), 1)
      }
      return actions
    }
  },
  methods: {
    initializeTable() {
      this.$refs.vtable.init();
    },
    getItems(params) {
      this.$store.dispatch("getRolesDeleted", params);
    },
    closeModal() {
      this.selItem = null;
      this.showModal = false;
    },
    onShowItem(item) {
      this.showModal = true;
      this.selItem = item;
    },
    onSaveItem() {
      this.initializeTable(); // Reset table
    },
    onActiveItem(item) {
      this.$alert
        .fireConfirm("Activar Rol", "Esta seguro que desea continuar?")
        .then(() => {
          this.$store.dispatch("restoreRole", item.id).then(() => {
           this.onSaveItem();
        });
        });
    },
    goToEvents: function () {
        this.$router.push('/roles')
    }
  },
};
</script>

<style>

</style>